import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Table } from 'reactstrap';
import { AccountInvitationSearchDto, Total } from '@just-ai/api/dist/generated/Accountsadmin';
import AccountsadminService from '@just-ai/api/dist/services/AccountsadminService';
import { Spinner, usePromiseProcessing, usePromisifyComponent } from '@just-ai/just-ui';

import { t } from 'localization';
import { axios } from 'pipes/functions';

import { NoAccounts } from 'components/NoAccounts';
import Pagination from 'components/Pagination';
import { usePagination } from 'components/Pagination/hook';
import TableSearchField from 'components/TableSearchField';
import DeleteInviteModal from 'views/AccountDetail/modals/DeleteInviteModal';
import EditInviteModal from 'views/AccountDetail/modals/EditInviteModal';

import InvitationRow from './InvitationRow';

import styles from './styles.module.scss';

const accountsadminService = new AccountsadminService(axios);

const COLS = ['email.id', 'invitation', 'name', 'groupsAndRoles'];

type InvitationType = { account: Total };
const Invitations = ({ account }: InvitationType) => {
  const pageParams = useParams<{ accountId: string }>();
  const { paginationInfo, changePageSize, changePage, changeTotalPages } = usePagination({
    totalCount: 0,
    pageNum: 0,
    pageSize: 10,
  });
  const [findInvitationsState, findInvitationsApi, optimisticFindInvitationsApi] = usePromiseProcessing(
    async (searchText: string) => {
      if (!pageParams.accountId) return;
      return accountsadminService.findInvitations({
        accountId: parseInt(pageParams.accountId),
        page: paginationInfo.pageNum,
        size: paginationInfo.pageSize,
        searchText,
      });
    },
    {
      deps: [pageParams.accountId, paginationInfo.pageNum, paginationInfo.pageSize],
    }
  );

  useEffect(() => {
    const pageSize = findInvitationsState.result?.paging.pageSize;
    if (pageSize !== undefined) changePageSize(pageSize);
    const totalCount = findInvitationsState.result?.paging.totalCount;
    if (totalCount !== undefined) changeTotalPages(totalCount);
  }, [
    changePageSize,
    changeTotalPages,
    findInvitationsState.result?.paging?.pageSize,
    findInvitationsState.result?.paging?.totalCount,
  ]);

  const [searchText, setSearchText] = useState('');

  const onFilterChange = useCallback(
    (searchText: string) => {
      changePage(0);
      setSearchText(searchText);
    },
    [changePage]
  );

  useEffect(() => {
    findInvitationsApi(searchText);
  }, [findInvitationsApi, searchText]);

  const [accountInvitation, setAccountInvitation] = useState<AccountInvitationSearchDto>();

  const [editInviteModalNode, callEditInviteModal] = usePromisifyComponent((resolve, reject, opened) => (
    <EditInviteModal
      isOpen={opened}
      onSubmit={resolve}
      onCancel={reject}
      accountInvitation={accountInvitation}
      accountId={account.id}
    />
  ));
  const onInviteEdit = useCallback(
    async (user: AccountInvitationSearchDto) => {
      setAccountInvitation(user);
      await callEditInviteModal();
      setAccountInvitation(undefined);
      optimisticFindInvitationsApi(searchText);
    },
    [callEditInviteModal, optimisticFindInvitationsApi, searchText]
  );

  const [deleteInviteModalNode, callDeleteInviteModal] = usePromisifyComponent((resolve, reject, opened) => (
    <DeleteInviteModal
      isOpen={opened}
      onSubmit={resolve}
      onCancel={reject}
      accountInvitation={accountInvitation}
      accountId={account.id}
    />
  ));
  const onDeleteUser = useCallback(
    async (accountInvitation: AccountInvitationSearchDto) => {
      setAccountInvitation(accountInvitation);
      await callDeleteInviteModal();
      setAccountInvitation(undefined);
      optimisticFindInvitationsApi(searchText);
    },
    [callDeleteInviteModal, optimisticFindInvitationsApi, searchText]
  );

  return (
    <div className={styles.Invitations}>
      {findInvitationsState.loading && <Spinner />}

      <TableSearchField
        value={searchText}
        onChange={onFilterChange}
        placeholder={t('AccountUsers:Search:Placeholder')}
      />

      {findInvitationsState.result?.records?.length ? (
        <>
          <Table data-test-id='usersPage.userListTable' className={styles.table}>
            <thead>
              <tr>
                {COLS.map(col => (
                  <th key={`user__${col}`}>{t(`AccountUsers:Table:Header:${col}`)}</th>
                ))}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {findInvitationsState.result.records.map(record => (
                <InvitationRow
                  key={`user-item-${record.invitationId}`}
                  invitation={record}
                  onDelete={onDeleteUser}
                  onEdit={onInviteEdit}
                />
              ))}
            </tbody>
          </Table>
          <div className={styles.Invitations__paginationContainer}>
            <Pagination
              page={paginationInfo.pageNum}
              size={paginationInfo.pageSize}
              totalCount={paginationInfo.totalCount}
              changePage={changePage}
              changePageSize={changePageSize}
            />
          </div>
        </>
      ) : !findInvitationsState.loading ? (
        <NoAccounts />
      ) : null}
      {deleteInviteModalNode}
      {editInviteModalNode}
    </div>
  );
};

Invitations.displayName = 'Invitations';

export default React.memo(Invitations);
