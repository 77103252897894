export const commonLocalization = {
  and: {
    eng: 'and',
    ru: 'и',
    pt: 'e',
    cn: 'and',
  },
  Cancel: {
    eng: 'Cancel',
    ru: 'Отмена',
    pt: 'Cancelar',
    cn: '取消',
  },
  Delete: {
    eng: 'Delete',
    ru: 'Удалить',
    pt: 'Excluir',
    cn: '删除',
  },
  'ForgotPassword: submit button text': {
    eng: 'Reset password',
    ru: 'Сбросить пароль',
    pt: 'Redefinir senha',
    cn: 'Reset password',
  },
  'ForgotPassword: form header text': {
    eng: 'Reset password',
    ru: 'Сброс пароля',
    pt: 'Redefinir senha',
    cn: 'Reset password',
  },
  'ForgotPassword: form header success text': {
    eng: 'We have sent you a link to restore access to your account by email. Follow the link to reset your password.',
    ru: 'Мы выслали ссылку для восстановления доступа к аккаунту вам на email. Перейдите по ссылке, чтобы сбросить пароль.',
    pt: 'Enviamos-lhe um link para restaurar o acesso por e-mail à sua conta. Siga o link para redefinir sua senha.',
    cn: 'We have sent you a link to restore access to your account by email. Follow the link to reset your password.',
  },
  'Sidebar: profile': {
    eng: 'Profile',
    ru: 'Профиль',
    pt: 'Perfil',
    cn: 'Profile',
  },
  'Sidebar: accounts': {
    eng: 'Accounts',
    ru: 'Аккаунты',
    pt: 'Accounts',
    cn: 'Accounts',
  },
  'Sidebar: features': {
    eng: 'Feature settings',
    ru: 'Настройки фич',
    pt: 'Feature settings',
    cn: 'Feature settings',
  },
  'Sidebar: users': {
    eng: 'Users',
    ru: 'Пользователи',
    pt: 'Users',
    cn: 'Users',
  },
  'Sidebar: admins': {
    eng: 'Administrators',
    ru: 'Администраторы',
    pt: 'Administrators',
    cn: 'Administrators',
  },
  'AccountOrUserUnsupported: form header text': {
    eng: 'Access denied',
    ru: 'Доступ запрещен',
  },
  'AccountOrUserUnsupported: form body text': {
    eng: 'You cannot access the product with the current account.',
    ru: 'Вы не можете воспользоваться продуктом с текущей учетной записью или аккаунтом.',
  },
  'AccountOrUserUnsupported: submit button text': {
    eng: 'Go to user space',
    ru: 'Перейти в личный кабинет',
  },
  'AccountOrUserUnsupported:ChangeAccount': {
    eng: 'Change account',
    ru: 'Сменить аккаунт',
  },

  'OauthPage: oauth error text accountsadmin.user.by.email.not.found': {
    eng: 'The user <span>$[1]</span> wasn’t found. You may have entered the wrong username, or your account is not registered. <a href="/c/login">Please try again</a> or contact your account admin',
    ru: 'Пользователь <span>$[1]</span> не найден. Возможно, вы указали неверный логин или ваша учетная запись не зарегистрирована. <a href="/c/login">Попробуйте еще раз</a> или обратитесь к вашему администратору',
    pt: 'O usuário <span>$[1]</span> não foi encontrado. Você pode ter digitado o nome de usuário errado ou sua conta não está registrada. <a href="/c/login">Tente novamente</a> ou entre em contato com o administrador da sua conta',
  },
  'OauthPage: oauth error text accountsadmin.authorization.account.blocked': {
    eng: 'The account is blocked. <a href="/c/login">Log in</a> to another account or contact your admin.',
    ru: 'Аккаунт заблокирован. <a href="/c/login">Авторизуйтесь</a> в другом аккаунте или обратитесь к вашему администратору.',
    pt: 'A conta está bloqueada. <a href="/c/login">Faça login</a> em outra conta ou entre em contato com seu administrador.',
  },
  'OauthPage: oauth error text accountsadmin.authorization.user.disabled': {
    eng: 'The user is blocked. <a href="/c/login">Log in</a> to another user or contact your admin.',
    ru: 'Пользователь заблокирован. <a href="/c/login">Авторизуйтесь</a> в другом пользователе или обратитесь к вашему администратору.',
    pt: 'A usuário está bloqueada. <a href="/c/login">Faça login</a> em outra usuário ou entre em contato com seu administrador.',
  },
  'OauthPage: oauth error text accountsadmin.user.by.email.not.found no email': {
    eng: 'This account has no email. <a href="/c/login">Log in</a> to another account or contact your admin to add an email',
    ru: 'У аккаунта отсутствует email. <a href="/c/login">Авторизуйтесь</a> в другом аккаунте или обратитесь к вашему администратору, чтобы привязать email',
    pt: 'Esta conta não tem e-mail. <a href="/c/login">Faça login</a> em outra conta ou entre em contato com seu administrador para adicionar um e-mail',
  },
  'OauthPage: oauth error text accountsadmin.authorization.user.can_login_only_with_external_sso': {
    eng: 'You are only allowed to sign in using SSO. <a href="/c/login">Sign in</a>',
    ru: 'Вам разрешен вход только с использованием SSO. <a href="/c/login">Авторизуйтесь</a>',
  },
  'OauthPage: oauth error text accountsadmin.authorization.maximum_numbers_of_users_exceeded': {
    eng: 'Maximum number of users exceeded. Please contact your administrator',
    ru: 'Превышено максимальное количество пользователей. Свяжитесь со своим администратором',
  },
  'OauthPage: oauth error text accountsadmin.authorization.user.sso_user_without_email': {
    eng: 'A login error occurred. We couldn’t get your email. Please contact our support team',
    ru: 'Возникла ошибка при входе в учетную запись. Не удалось получить ваш email. Пожалуйста, обратитесь в поддержку',
  },
  'OauthPage: oauth error text unknownError': {
    eng: 'An error occurred. Please contact our support team and report the error code: {errorCode}',
    ru: 'Возникла ошибка. Пожалуйста, обратитесь в поддержку и сообщите код ошибки: {errorCode}',
  },
  'Pagination:Founded': {
    eng: 'Found: {size}',
    ru: 'Найдено: {size}',
    pt: 'Encontrado: {size}',
  },
  'Pagination:ToThePage': {
    eng: 'To page',
    ru: 'К странице',
    pt: 'Para a página',
  },
  'Pagination:ShowSize': {
    eng: 'Display',
    ru: 'Показать',
    pt: 'Mostrar',
  },
  'List:NoItems': {
    eng: 'Sorry, but we couldn’t find anything…',
    ru: 'Мы искали, но ничего не нашли…',
  },
  'UserMode:Regular': {
    ru: 'Перейти в режим пользователя',
    eng: 'Switch to user mode',
  },
  'UserMode:Internal': {
    ru: 'Перейти в режим администратора',
    eng: 'Switch to admin mode',
  },
  'GlobalErrorBoundary:Text': {
    eng: 'Something went wrong.',
    ru: 'Что-то пошло не так.',
  },
  'GlobalErrorBoundary:Description': {
    eng: 'Please, contact the the technical support about this error.',
    ru: 'Пожалуйста, обратитесь в службу технической поддержки по поводу этой ошибки.',
  },
  'GlobalErrorBoundary:TryAgain': {
    eng: 'Try again',
    ru: 'Повторить',
  },
};
