import React, { lazy, Suspense } from 'react';
import { OmitNative, RouteProps } from 'react-router';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { Spinner } from '@just-ai/just-ui/dist';

import AppErrorBoundary from 'components/AppErrorBoundary';
import AuthWrapper from 'components/AuthWrapper';
import WithSidebar from 'components/WithSidebar';
import isAccess from 'helpers/isAccessFunction';
import { AcceptInvitationByEmail } from 'views/AcceptInvitation';
import ExternalSso from 'views/ExternalSso';

import Full from './components/Full';
import AccountOrUserUnsupported from './views/AccountOrUserUnsupported';
import { AddUsers } from './views/AddUsers';
import CountrySelect from './views/CountrySelect';
import ErrorPage from './views/ErrorPage';
import ExtensionPage from './views/ExtensionPage';
import ForgotPassword from './views/ForgotPassword';
import { InvitePage } from './views/InvitePage';
import { InviteToProductForm } from './views/InviteToProductForm';
import Login from './views/Login';
import Logout from './views/Logout';
import OauthPage from './views/OauthPage';
import Page404 from './views/Page404';
import PhoneVerificationAndLogin from './views/PhoneVerificationAndLogin';
import ProjectGroupCreate from './views/ProjectGroupCreate';
import ProjectGroupSelect from './views/ProjectGroupSelect';
import Register from './views/Register';
import RegistrationWithInvite from './views/RegistrationWithInvite';
import ResetPassword from './views/ResetPassword';
import VerifyEmail from './views/VerifyEmail';
import { history } from './App';

const UserProfile = lazy(() => import('./views/UserProfile'));
const Accounts = lazy(() => import('./views/Accounts'));
const AccountDetail = lazy(() => import('./views/AccountDetail'));
const Features = lazy(() => import('./views/Features'));
const Users = lazy(() => import('./views/Users'));
const Admins = lazy(() => import('./views/Admins'));

export type SideBarItem = {
  link: string;
  title: string;
  access?: string | string[];
};

export const getAllowedRoutes = () => {
  return [
    { link: '/c/profile', title: 'profile' },
    { link: '/c/accounts', title: 'accounts' },
    { link: '/c/users', title: 'users', access: 'ACCOUNTS_READ' },
    { link: '/c/system_admins', title: 'admins', access: 'ACCOUNTS_READ' },
    { link: '/c/features', title: 'features', access: 'FEATURES_READ' },
  ].filter(item => !item.access || isAccess(item.access));
};

type InnerRouteProps<PROPS extends {} = {}, Path extends string = string> = RouteProps<Path> &
  OmitNative<PROPS, keyof RouteProps> & {
    access?: string | string[];
    orAccess?: string | string[];
    systemAccess?: string | string[];
  };

const InnerRoute = <PROPS extends {} = {}, PATH extends string = string>({
  ...props
}: InnerRouteProps<PROPS, PATH>) => {
  if (!props.access || isAccess(props.access) || isAccess(props.orAccess)) {
    return (
      <AppErrorBoundary prefix='SidebarPages:Routes' key={String(props.path)}>
        <Route {...props} />
      </AppErrorBoundary>
    );
  }
  return <Redirect to={{ pathname: '/', state: { from: props.location } }} />;
};

const Routes = () => {
  return (
    <Router history={history}>
      <Suspense fallback={<Spinner size='4x' />}>
        <Switch>
          <Route path='/c/register' exact component={Register} />
          <Route path='/c/register-with-invitation' exact component={RegistrationWithInvite} />
          <Route path='/c/login' exact component={Login} />
          <Route path='/c/login/oauth2' exact component={OauthPage} />
          <Route path='/c/login-with-phone' exact component={PhoneVerificationAndLogin} />
          <Route path='/c/verify-email' exact component={VerifyEmail} />
          <Route path='/c/accept-invitation' exact component={AcceptInvitationByEmail} />
          <Route path='/c/forgot-password' exact component={ForgotPassword} />
          <Route path='/c/reset-password' exact component={ResetPassword} />
          <Route path='/c/phone-verification' exact component={PhoneVerificationAndLogin} />
          <Route path='/c/select-country' exact component={CountrySelect} />
          <Route path='/c/extension' exact component={ExtensionPage} />
          <Redirect from='/c/only-platform' to='/c/account-or-user-unsupported' exact />
          <Route path='/c/account-or-user-unsupported' exact component={AccountOrUserUnsupported} />
          <Redirect exact strict from='/c/select-project-group' to='/c/select-account' />
          <Redirect exact strict from='/c/account-or-user-unsupported' to='/c/select-account' />
          <Route path='/c/select-account' exact component={ProjectGroupSelect} />
          <Route path='/c/create-project-group' exact component={ProjectGroupCreate} />
          <Route path='/c/logout' exact component={Logout} />
          <Route path='/c/external-sso' exact component={ExternalSso} />
          <Route path='/c/error' exact component={ErrorPage} />
          <AuthWrapper>
            <Full>
              <Switch>
                <Redirect exact strict from='/' to='/c/accounts' />
                <Route
                  path='/c'
                  render={() => (
                    <WithSidebar>
                      <Switch>
                        <InnerRoute path='/c/profile' exact component={UserProfile} />
                        <InnerRoute path='/c/accounts' exact component={Accounts} />
                        <InnerRoute path='/c/accounts/:accountId' exact component={AccountDetail} />
                        <InnerRoute path='/c/accounts/:accountId/invite' exact component={InvitePage} />
                        <InnerRoute
                          path='/c/accounts/:accountId/invite/:productDomain'
                          exact
                          component={InviteToProductForm}
                        />
                        <InnerRoute
                          access={['ACCOUNTS_ADMIN_WRITE']}
                          path='/c/accounts/:accountId/add'
                          exact
                          component={AddUsers}
                        />
                        <InnerRoute access={['ACCOUNTS_READ']} path='/c/system_admins' exact component={Admins} />
                        <InnerRoute access={['ACCOUNTS_READ']} path='/c/users' exact component={Users} />
                        <InnerRoute access={['FEATURES_READ']} path='/c/features' exact component={Features} />
                        <Route component={Page404} />
                      </Switch>
                    </WithSidebar>
                  )}
                />
                <Route component={Page404} />
              </Switch>
            </Full>
          </AuthWrapper>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
