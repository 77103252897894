export const errorPageLocalization = {
  'ErrorPage:Error:accountsadmin.account.invitation.not_found': {
    ru: 'Приглашение не найдено',
    eng: 'Invitation not found',
  },
  'ErrorPage:Error:Message:accountsadmin.account.invitation.not_found': {
    ru: 'Возможные причины: приглашение истекло, было отозвано администратором, или вы его уже приняли/отклонили.',
    eng: 'Possible reasons: the invitation has expired, was revoked by the administrator, or you’ve already accepted/declined it.',
  },
  'ErrorPage:Error:accountsadmin.account.invitation.expired': {
    ru: 'Приглашение просрочено',
    eng: 'Invitation expired',
  },
  'ErrorPage:Error:Message:accountsadmin.account.invitation.expired': {
    ru: 'Свяжитесь с администратором аккаунта, чтобы получить повторное приглашение.',
    eng: 'Please contact the account administrator to receive a new invitation.',
  },
  'ErrorPage:Error:accountsadmin.account.invitation.multiple_registered_users_with_same_email': {
    ru: 'Нужны дополнительные действия',
    eng: 'Additional action required',
  },
  'ErrorPage:Error:Message:accountsadmin.account.invitation.multiple_registered_users_with_same_email': {
    ru: 'Перейдите в <a href={ccLink} rel="noopener noreferrer">личный кабинет</a> и примите приглашение.',
    eng: 'Please go to <a href={ccLink} rel="noopener noreferrer">your profile</a> and accept the invitation.',
  },
  'ErrorPage:Error:accountsadmin.account.invitation.registration_disabled': {
    ru: 'Невозможно присоединиться к аккаунту',
    eng: 'Unable to join the account',
  },
  'ErrorPage:Error:Message:accountsadmin.account.invitation.registration_disabled': {
    ru: 'К аккаунту могут присоединяться только зарегистрированные пользователи. Свяжитесь с администратором аккаунта.',
    eng: 'Only registered users can join this account. Please contact the account administrator.',
  },
  'ErrorPage:Error:accountsadmin.account.invitation.not.found.by.user': {
    ru: 'Приглашение не найдено',
    eng: 'Invitation not found',
  },
  'ErrorPage:Error:Message:accountsadmin.account.invitation.not.found.by.user': {
    ru: 'Возможные причины: приглашение истекло, было отозвано администратором, или вы его уже приняли/отклонили.',
    eng: 'Possible reasons: the invitation has expired, was revoked by the administrator, or you’ve already accepted/declined it.',
  },
  'ErrorPage:Error:accountsadmin.account.too.many.users.added': {
    ru: 'Невозможно присоединиться к аккаунту',
    eng: 'Unable to join the account',
  },
  'ErrorPage:Error:Message:accountsadmin.account.too.many.users.added': {
    ru: 'В этом аккаунте уже максимальное количество пользователей. Свяжитесь с администратором аккаунта.',
    eng: 'This account has reached its maximum user limit. Please contact the account administrator.',
  },
  'ErrorPage:Error:accountsadmin.account.invitation.bind_to_external_user_without_email': {
    ru: 'Нужны дополнительные действия',
    eng: 'Additional action required',
  },
  'ErrorPage:Error:Message:accountsadmin.account.invitation.bind_to_external_user_without_email': {
    ru: 'Вы пытаетесь зарегистрироваться с помощью стороннего сервиса. Мы не можем верифицировать ваш email. Чтобы присоединиться к аккаунту, выполните одно из следующего: \n <ul><li>Пройдите по ссылке из письма и зарегистрируйтесь при помощи email и пароля.</li><li>Сделайте email доступным для чтения в OAuth на стороннем сервисе и пройдите по ссылке из письма заново. Подробнее читайте в <a target="_blank" href={docsLink} rel="noopener noreferrer">документации</a>.</li></ul>',
    eng: 'You’re trying to register using an external service. We can’t verify your email. To join the account, please do one of the following: \n <ul><li>Follow the link from the letter and register using your email and password.</li><li>Make your email readable in OAuth on the external service and click the link in the invitation again. Read more in the <a target="_blank" href={docsLink} rel="noopener noreferrer">documentation</a>.</li></ul>',
  },
  'ErrorPage:Error:accountsadmin.account.invitation.bind_to_external_user_wrong_email': {
    ru: 'Нужны дополнительные действия',
    eng: 'Additional action required',
  },
  'ErrorPage:Error:Message:accountsadmin.account.invitation.bind_to_external_user_wrong_email': {
    ru: 'Вы пытаетесь зарегистрироваться с помощью стороннего сервиса или SSO. Ваш email не совпадает с тем, на который было отправлено приглашение. Чтобы присоединиться к аккаунту, выполните одно из следующего: \n <ul><li>Пройдите по ссылке из письма и зарегистрируйтесь при помощи email и пароля.</li><li>При входе выберите учетную запись на стороннем сервисе, которая привязана к email из приглашения.</li></ul>',
    eng: 'You’re trying to register using an external service or SSO. Your email doesn’t match the one the invitation was sent to. To join the account, please do one of the following: \n <ul><li>Click the link in the invitation and register using your email and password.</li><li>When logging in, choose the account on the external service that is linked to the email from the invitation.</li></ul>',
  },
  'ErrorPage:Error:Link': {
    ru: '#{config.helpUrl}/accounts/#accept-invitation',
    eng: '#{config.helpUrl}/accounts/#accept-invitation',
  },
  'ErrorPage:Error:accountsadmin.authorization.user.disabled': {
    eng: 'The user is blocked',
    ru: 'Пользователь заблокирован',
  },
  'ErrorPage:Error:Message:accountsadmin.authorization.user.disabled': {
    eng: 'The user is blocked. <a href="/c/login">Log in</a> to another user or contact your admin.',
    ru: 'Пользователь заблокирован. <a href="/c/login">Авторизуйтесь</a> в другом пользователе или обратитесь к вашему администратору.',
  },
};
